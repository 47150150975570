export const players = [
    "Aaron Oliver", "Luke Williams",
    "Bazzy Shultz", "Ryan Duross",
    "Jack Lofwall", "Caleb Liu",
    "Anthony Sausedo", "Will Furlow",
    "Kabir Jolly", "Kirin Atluru",
    "Donnie Raymond", "Cole Matthes",
    "Afnaan Hashmi", "Luke McFall",
    "Eli Toney", "Trevor Golob",
    "Miggy Villanueva", "Cole Robins",
    "Joseph Machado", "Vaughn Nahapetian",
    "Ryan Beyer", "Thomas Yim",
    "Asher Fanous", "Alex Gu",
    "Ethan Chang", "Danny Goldberg",
    "Dash Beavers", "Akash Gupta",
    "Kenny Oseleononmen", "Ryan Brennan"
]